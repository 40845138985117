import { storeToRefs } from 'pinia'
import { times, round } from 'lodash-es'
import { CalculatorItem, PartnerSuggestions, UseInstallmentCalculator } from '~/composables/useInstallmentCalculator/useInstallmentCalculator'
import { useInstallmentCalculatorStore } from '~/composables/useInstallmentCalculator/store/useInstallmentCalculatorStore'
import { useI18n } from '~/helpers/hooks/usei18n'
import { periodConfig, depositConfig, interestConfig } from '~/resources/calculator/config'
import { safeComputed } from '~/modules/helpers'

export function useInstallmentCalculator (): UseInstallmentCalculator {
  const installmentCalculatorStore = useInstallmentCalculatorStore()
  const { fetch } = installmentCalculatorStore
  const i18n = useI18n()

  const data = safeComputed(() => installmentCalculatorStore.data)

  function generatePeriodItems (): CalculatorItem[] {
    return times((periodConfig.range.max / periodConfig.step) + 1, (index: number): CalculatorItem => {
      return {
        value: (index * periodConfig.step).toString()
      }
    })
  }

  function generateDepositItems (price: number): CalculatorItem[] {
    return times((depositConfig.range.max / depositConfig.step) + 1, (index: number): CalculatorItem => {
      const percent = (index * depositConfig.step).toString()
      return {
        value: percent,
        description: i18n.n((price * +percent / 100), 'currency', 'et')
      }
    })
  }

  function generateInterestItems (): CalculatorItem[] {
    return times((interestConfig.range.max / interestConfig.step) + 1, (index: number): CalculatorItem => {
      return {
        value: round(index * interestConfig.step, 1)
      }
    })
  }

  function getMonthlyPayment (price: number, partnerSuggestions: PartnerSuggestions): number {
    if (!partnerSuggestions.interest || !partnerSuggestions.period) {
      return price
    }

    const priceWithoutDeposit: number = price - price * ((partnerSuggestions.deposit ?? 0) / 100)
    const interestInMonth: number = partnerSuggestions.interest / 100 / 12
    const x: number = Math.pow(1 + interestInMonth, partnerSuggestions.period)
    return priceWithoutDeposit * x * interestInMonth / ((x - 1) || 1)
  }

  function calculateMinMonthlyPayment (price: number): number | undefined {
    if (!data.value) {
      return
    }

    return Math.min(...data.value.map(partner => getMonthlyPayment(price, {
      period: partner.period,
      interest: partner.interest_rate
    })))
  }

  return {
    data,
    fetch,
    periodConfig,
    generatePeriodItems,
    depositConfig,
    generateDepositItems,
    interestConfig,
    generateInterestItems,
    calculateMinMonthlyPayment,
    getMonthlyPayment
  }
}
