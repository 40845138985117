import { defineStore } from 'pinia'
import { Ref, ref, shallowRef } from '@nuxtjs/composition-api'
import { useApi } from '~/composables'
import {
  InstallmentCalculatorStore,
  PaymentPartner,
  PaymentPartners
} from '~/composables/useInstallmentCalculator/useInstallmentCalculator'
import { getPaymentPartnersCommand } from '~/composables/useInstallmentCalculator/command/getPaymentPartnersCommand'

export const useInstallmentCalculatorStore = defineStore('installment-calculator', (): InstallmentCalculatorStore => {
  const { query } = useApi()
  const data: Ref<PaymentPartner[]> = ref(null)
  const awaitedPromise = shallowRef<Promise<PaymentPartner[]> | undefined>(undefined)

  async function fetch (force?: boolean): Promise<PaymentPartner[]> {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (awaitedPromise.value) {
      return await awaitedPromise.value
    }

    if (!force && data.value) {
      return
    }

    awaitedPromise.value = query<{ getPaymentPartners: PaymentPartners }>(getPaymentPartnersCommand).then((result) => {
      const { data: partners } = result
      data.value = partners?.getPaymentPartners?.items ?? []
      return data.value
    }).finally(() => {
      awaitedPromise.value = undefined
    })

    return await awaitedPromise.value
  }

  return {
    data,
    fetch
  }
})
