
import { useFetch } from '@nuxtjs/composition-api'

import { useInstallmentCalculator } from '~/composables/useInstallmentCalculator'
import { useImage } from '~/composables'
import { safeComputed } from '~/modules/helpers'

export default {
  name: 'BankLogos',
  setup () {
    const { fetch: loadPaymentPartners, data: loadedPartners } = useInstallmentCalculator()
    const { getMagentoImage } = useImage()
    const frontPageBankLogos = safeComputed(() => loadedPartners.value?.filter(partner => !!partner.front_page) ?? [])

    useFetch(async () => {
      await loadPaymentPartners()
    })

    return {
      loadedPartners,
      getMagentoImage,
      frontPageBankLogos
    }
  }
}
