export const getPaymentPartnersCommand = `
  mutation {
  getPaymentPartners {
    items{
      id
      partner_name
      interest_rate
      url
      external_url
      front_page
      loan_calculator
      logo
      period
      down_payment
    }
  }
}
`
