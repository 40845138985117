import { CalculatorSliderConfig } from '~/composables/useInstallmentCalculator/useInstallmentCalculator'

export const periodConfig: CalculatorSliderConfig = {
  start: 0,
  range: {
    min: 0,
    max: 24
  },
  step: 3
}

export const depositConfig: CalculatorSliderConfig = {
  start: 0,
  range: {
    min: 0,
    max: 80
  },
  step: 10
}

export const interestConfig: CalculatorSliderConfig = {
  start: 0,
  range: {
    min: 0,
    max: 19
  },
  step: 1.9
}
